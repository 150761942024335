import './App.css';

import { useEffect, useState } from 'react';

function App() {

  const [data, setData] = useState<any>({});
  const [wtf, setWtf] = useState(false);
  let message = "";
  const token = "isnielsdea-8417242513fa87ce";

  const msgs = {
    staleData: "...maybe?",
    below70: "Almost.",
    above300: "Pretty much.",
    above200: "Kinda.",
    inRange: "Nope."
  }

  useEffect(() => {
    fetch(`https://loop.niels.me/api/v1/entries/current.json?token=${token}`)
      .then(response => response.json())
      .then(data => setData(data));
  }, []);

  let bg = null;
  if (data.length && data[0].sgv) bg = data[0].sgv;
  else return null;
  const date = new Date(data[0].dateString);

  // If date is longer than 1 hour ago
  if (date.getTime() < Date.now() - 1000 * 60 * 60) {
    message = msgs.staleData
  }

  // If bg is lower than 70
  else if (bg < 70) {
    message = msgs.below70;
  }

  // If bg is higher than 300
  else if (bg > 300) {
    message = msgs.above300;
  }

  // If bg is higher than 200
  else if (bg > 200) {
    message = msgs.above200;
  }

  // If within range  
  else message = msgs.inRange;

  if (message !== msgs.inRange) {
    // Wait 5 seconds, then redirect
    setTimeout(() => {
      window.location.href = `https://loop.niels.me?token=${token}`;
    }, 5000);
  }

  document.title = message;
  const showToggle = message === msgs.inRange && !wtf;
  return (
    <div className="App">
      {showToggle ?
        <h1 style={{ display: "flex", alignItems: "center" }}>
          <span>Alive</span>
          <input type="checkbox" id="switch" onChange={() => setTimeout(() => setWtf(true), 100)} />
          <label id="toggle" htmlFor="switch" style={{ margin: "1em" }}>Toggle</label>
          <span>Dead</span>
        </h1>
        :
        <h1>{wtf ? "Dude. WTF?" : message}</h1>}

    </div>
  );
}

export default App;
